import React, { useState, useEffect } from 'react'
import { css } from 'emotion'
import { CFView, PrimaryModal } from 'components'
import { dispatch } from 'store'

export default () => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const welcomeMessage = dispatch.restaurant.getWelcomeMessage()
    if (welcomeMessage.title) {
      setShowModal(true)
    }
  }, [])

  const closeModal = () => {
    setShowModal(false)
    // localStorage.setItem('welcomeSeen', true)
  }

  return (
    <PrimaryModal
      title="Closing Dec 31st, 2022"
      isOpen={showModal}
      onRequestClose={closeModal}
    >
      <CFView className={styles.container}>
        <CFView column w="100%" pb="10px" textCenter>
          <CFView h3 black textCenter pb="15px">
            To our dearly valued customers,
          </CFView>
          <CFView h3 black textCenter pb="15px">
            Haru Sushi will be closed by the end of December 2022. It's been
            such a wonderful 20 years serving all of you! We would like to
            sincerely thank you for all your kind support throughout the years,
            and we will treasure the memories forever. For customers still
            having gift cards and reward points with us, please use them by
            December 31st, 2022.
          </CFView>
          <CFView h3 black textCenter>
            We wish you a great holiday and amazing new adventures ahead!
          </CFView>
        </CFView>
      </CFView>
    </PrimaryModal>
  )
}

const styles = {
  container: css({
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '640px',
    '@media (max-width: 767px)': {
      width: '100%',
    },
  }),
}
